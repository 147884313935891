import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Document } from "@contentful/rich-text-types";
import { mdiMusic } from "@mdi/js";
import { Icon } from "@mdi/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Video } from "../interfaces/video";

interface CardProps {
  video: Video;
}
export const Card: React.FC<CardProps> = ({ video }) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <div className="column is-half">
      <CreditsModal
        isVisible={isVisible}
        setVisible={setVisible}
        credits={video.credits}
        title={video.title}
      />
      <Link to={`/${video.id}`}>
        <div className="card">
          <div className="card-image">
            <figure className="image is-16by9">
              <img src={video.thumbnail.fields.file.url} alt="day" />
            </figure>
          </div>
          <div className="card-content">
            <div className="content">
              <p>{video.title}</p>
              {video.credits && (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(true);
                  }}
                >
                  <Icon path={mdiMusic} />
                </span>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

interface CreditsModalProps {
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
  credits?: Document;
  title?: string;
}
export const CreditsModal: React.FC<CreditsModalProps> = ({
  isVisible,
  setVisible,
  credits,
  title = "",
}) => {
  const toggleModal = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    show = true
  ) => {
    e.preventDefault();
    setVisible(show);
  };
  const modalClass = isVisible ? "modal is-active" : "modal";

  const renderCredits = (): string => {
    if (!credits) {
      return "";
    }

    const rawCredits = documentToHtmlString(credits);

    return rawCredits.replace(/<a href=/g, '<a target="_blank" href=');
  };

  return (
    <div className={modalClass}>
      <div
        className="modal-background"
        onClick={(e) => toggleModal(e, false)}
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={(e) => toggleModal(e, false)}
          ></button>
        </header>
        <section
          className="modal-card-body"
          dangerouslySetInnerHTML={{
            __html: renderCredits(),
          }}
        ></section>
      </div>
    </div>
  );
};
