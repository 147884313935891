import React, { useState } from "react";
import { Video } from "../interfaces/video";
import { getAllVideos } from "../utils/contentful";

interface VideosContext {
  getVideo: (id: string) => Promise<Video | undefined>;
  getVideos: () => Promise<Video[]>;
  playNextId: (currentId: string) => string;
}

const VideosContext = React.createContext<VideosContext>({
  getVideo: () => Promise.resolve(undefined),
  getVideos: () => Promise.resolve([]),
  playNextId: () => ""
});

const VideosProvider: React.FC = ({ children }) => {
  const [videos, setVideos] = useState<Video[]>();

  const getVideo = async (id: string): Promise<Video | undefined> => {
    if (!videos || !videos.length) {
      const fetchedVideos = await getVideos();

      return fetchedVideos.find((v) => v.id === id);
    }

    return videos.find((v) => v.id === id);
  };

  const getVideos = async () => {
    if (!videos || !videos.length) {
      const entries = await getAllVideos();

      const fetchedVideos: Video[] = entries.items.map((e) => ({
        ...e.fields,
        id: e.sys.id
      }));

      setVideos(fetchedVideos);

      return fetchedVideos;
    }

    return videos;
  };

  const playNextId = (currentId: string) => {
    if (!videos || !videos.length) {
      return "";
    }

    const videoIds = videos.map((v) => v.id);
    const doubleVideos = [...videoIds, ...videoIds];
    const currentIndex = doubleVideos.findIndex((id) => id === currentId);

    return doubleVideos[currentIndex + 1];
  };

  return (
    <VideosContext.Provider value={{ getVideo, getVideos, playNextId }}>
      {children}
    </VideosContext.Provider>
  );
};

export { VideosProvider, VideosContext };
