import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { VideosProvider } from "./context/videos.context";
import { Home } from "./pages/home";
import { VideoPage } from "./pages/video-page";

function App() {
  return (
    <VideosProvider>
      <Router>
        <Switch>
          <Route path="/:id" component={VideoPage} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </VideosProvider>
  );
}

export default App;
