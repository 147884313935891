import * as contentful from "contentful";
import { Video } from "../interfaces/video";

const client = contentful.createClient({
  space: "hc1y2wck6uy7",
  accessToken: "IpovLRPWdGzF-ei9_LLDUaMTtuBHd6gdbfUfV5UyVBE",
});

export const getAllVideos = () =>
  client.getEntries<Video>({
    order: "-sys.createdAt",
  });
export const getVideo = (id: string) => client.getEntry<Video>(id);

export default client;
