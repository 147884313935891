import React from "react";

export const Disclaimer: React.FC = () => {
  return (
    <div className="container intro-text">
      <p>WE DO NOT OWN ANY COPYRIGHT ON THE SONGS.</p>
      <p>YOU CAN FIND LINKS TO EACH SONG ON YOUTUBE'S ARTIST PAGE.</p>
      <p>WE DON'T WANT TO MAKE ANY MONEY WITH THIS.</p>
      <p>SO PLEASE, FOLLOW AND SUPPORT THE ARTISTS.</p>
    </div>
  );
};
