import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { VideosContext } from "../context/videos.context";
import { Video } from "../interfaces/video";

interface VideoPageParams {
  id: string;
}
export const VideoPage: React.FC = () => {
  const { id } = useParams<VideoPageParams>();
  const [video, setVideo] = useState<Video>();
  const { getVideo, playNextId } = useContext(VideosContext);
  const history = useHistory();

  useEffect(() => {
    getVideo(id).then((v) => {
      setVideo(v);
    });
    // eslint-disable-next-line
  }, [id]);

  const onEnded = () => {
    const newVideoId = playNextId(id);
    history.push(newVideoId);
  };

  if (!video) {
    return (
      <div className="loading">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div id="video">
      <div className="close">
        <Link to="/">
          <p>X</p>
        </Link>
      </div>
      <video
        controls
        autoPlay
        controlsList="nodownload"
        playsInline={true}
        onEnded={onEnded}
        key={video.url}
      >
        <source src={video.url} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  );
};
