import React from "react";
import { Disclaimer } from "../components/disclaimer";
import { Intro } from "../components/intro";
import { VideoContainer } from "../components/video-container";

export const Home: React.FC = () => {
  return (
    <>
      <Intro />
      <VideoContainer />
      <Disclaimer />
    </>
  );
};
