import React, { useContext, useEffect, useState } from "react";
import { VideosContext } from "../context/videos.context";
import { Video } from "../interfaces/video";
import { Card } from "./card";

export const VideoContainer: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const { getVideos } = useContext(VideosContext);

  useEffect(() => {
    getVideos().then(v => {
      setVideos(v);
    });
  }, [getVideos]);

  const renderVideos = videos.map(v => <Card video={v} key={v.id} />);
  return (
    <div className="video-container container">
      <div className="columns is-marginless is-multiline">{renderVideos}</div>
    </div>
  );
};
