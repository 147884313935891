import React from "react";
import ig from "../assets/ig.png";

export const Intro: React.FC = () => {
  return (
    <div className="container intro-text">
      <p>IN THESE FOGGY AND UNCERTAIN TIMES,</p>
      <p>IT’S JUST NORMAL WE OFTEN FORGET THE SUN IT’S STILL OUT THERE.</p>
      <p>COMING AND GOING, EVERY DAY.</p>
      <p>AND SINCE SOME AREN’T LUCKY ENOUGH TO SEE IT,</p>
      <p>THESE SUNSETS SOUNDS ARE A REMINDER.</p>
      <p>FOR WHEN WE NEED SOME.</p>
      <div className="follow-us-container">
        <a
          className="follow-us"
          href="https://www.instagram.com/sunsetsounds.cam/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={ig} alt="Follow us for updates on Instagram" />
          <p>FOLLOW US FOR UPDATES</p>
        </a>
      </div>
    </div>
  );
};
